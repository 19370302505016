<template>
  <v-bottom-sheet v-model="dialog" width="500" v-if="value">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item link v-bind="attrs" v-on="on">
        <ListIcon>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon :color="color" v-bind="attrs" v-on="on">{{
                icon
              }}</v-icon>
            </template>
            <span>{{ tooltip }}</span>
          </v-tooltip>
        </ListIcon>
        <v-list-item-content>
          <v-list-item-subtitle> {{ timespan }} </v-list-item-subtitle>
          <v-list-item-title :class="value.canceled ? 'canceled' : ''">{{
            value.description
          }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  :class="value.cancelText ? 'red--text' : ''"
                  >{{ participantsCode }}
                </span>
              </template>
              <span>
                {{ value.participantsText }}
                <span v-if="value.cancelText"
                  >Unterrichtsausfall:&nbsp;{{ value.cancelText }}</span
                ></span
              >
            </v-tooltip>
          </v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>
    </template>
    <EventSheet :value="value" @close="dialog = false"> </EventSheet>
  </v-bottom-sheet>
</template>
<script>
import { defineComponent } from "vue";
import { formatDatespan, formatTimespan } from "common/utils/date.js";
import { eventColor, eventIcon } from "common/utils/icons.js";
import EventSheet from "common/components/EventSheet.vue";
export default defineComponent({
  name: "EventItem",
  components: { EventSheet },
  props: {
    active: {
      type: Boolean,
      default: () => false,
    },
    value: {},
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    participantsCode() {
      const participants = this.value.participants.filter((el) =>
        ["course", "students", "schoolClass"].includes(el.type.code)
      );
      if (participants.length == 1) {
        if (participants[0].course) {
          return participants[0].course.code;
        }
        if (participants[0].grade) {
          return (
            participants[0].grade.code +
            (this.value.division ? " " + this.value.division.code : "")
          );
        }
        if (participants[0].schoolClass) {
          return participants[0].schoolClass.code;
        }
      }
      if (
        this.value.participants.length == 1 &&
        this.value.participants[0].type.code == "teachers"
      ) {
        return (
          "LK" + (this.value.division ? " " + this.value.division.code : "")
        );
      }
      return this.value.division ? this.value.division.code : "GMW";
    },
    color() {
      return eventColor(this.value);
    },
    dateSpan() {
      return formatDatespan(this.value.startDate, this.value.endDate, true);
    },
    icon() {
      return eventIcon(this.value);
    },
    timespan() {
      return formatTimespan(
        this.value.startDate,
        this.value.startTime,
        this.value.endDate,
        this.value.endTime
      );
    },
    tooltip() {
      let result = "";
      if (this.value.current) {
        result += "aktueller ";
      } else if (this.value.past) {
        result += "vergangener ";
      } else if (this.value.future) {
        result += "zukünftiger ";
      }
      if (this.value.canceled) {
        result += "abgesagter ";
      }
      if (this.value.changed) {
        result += "geänderter ";
      }
      result += "Termin";
      if (this.value.staffOnly) {
        result += " (nur für LK)";
      }
      return result;
    },
  },
});
</script>

<style scoped>
.canceled {
  text-decoration-line: line-through;
}
</style>
